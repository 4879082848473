<template>
  <div class="container">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Browse' }">
            Browse
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Examination', query: { examId } }">
            {{ testName }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <router-link :to="{ name: 'Passages', query: { examId } }">
            {{ `${sectionName} Passages` }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          {{ pageTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="shortcutBtns">
      <router-link :to="{ name: 'Passages' }">
        <el-button type="success">回到文章列表</el-button>
      </router-link>
      <router-link
        :to="
          `/tests/${$route.params.testType}/test/${$route.params.testTypeId}/section/${$route.params.sectionId}/question`
        "
      >
        <el-button type="success">回至題目列表</el-button>
      </router-link>
    </div>
    <div>
      <h1>
        {{ pageTitle }}
        <router-link
          :to="{ name: 'EditPassage', query: { passageId, examId } }"
        >
          <i class="fas fa-edit action-icon" />
        </router-link>
      </h1>
      <div style="display: flex; align-items: center">
        <el-switch
          style="margin-right: 4px"
          v-model="isDisplaySingleQuestion"
          @change="changeToSingleQuestionMode"
          active-color="var(--themeColor)"
          inactive-color="var(--themeColor)"
          active-text="單題顯示"
          inactive-text="顯示該 Passage 所有題目"
        />
        <div
          v-if="isDisplaySingleQuestion"
          style="display: flex; flex-wrap: wrap"
        >
          <span
            :style="
              `
              background: ${
                question.order === Number($route.query.questionOrder)
                  ? 'var(--themeColor)'
                  : ''
              };
              color: ${
                question.order === Number($route.query.questionOrder)
                  ? '#fff'
                  : ''
              }
            `
            "
            class="questionOrder"
            v-for="question in passage.questions"
            :key="question.order"
            @click="enterToSingleQuestion(question.order)"
          >
            {{ question.order }}
          </span>
        </div>
      </div>
      <div v-if="passage" class="ivy-content">
        <div class="ivy-practice-bodyPage">
          <div class="view_questions">
            <el-row>
              <el-col :span="12">
                <div class="ivy-passage">
                  <PassageViewer
                    :key="question"
                    :passage="passage"
                    :section="sectionName"
                  />
                </div>
              </el-col>
              <el-col :span="12">
                <div class="ivy-quesitons">
                  <QuestionViewer
                    v-for="question in filterQuestion(passage.questions)"
                    :key="question.order"
                    :examId="$route.params.examId"
                    :sectionId="$route.params.sectionId"
                    :question="question"
                    :disableDelete="false"
                    @reload="fecthPassage"
                  />
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import PassageViewer from "@/components/tests/PassageViewer.vue";
import QuestionViewer from "@/components/tests/QuestionViewer.vue";
import satApi from "@/apis/sat.js";

export default {
  metaInfo() {
    return {
      title: `${this.pageTitle} - ${this.CompanyName}`
    };
  },

  components: { Breadcrumb, PassageViewer, QuestionViewer },

  props: [],
  data() {
    return {
      isDisplaySingleQuestion: false,
      sectionName: "",
      testName: "",
      passage: {}
    };
  },
  computed: {
    pageTitle() {
      return `${this.passage.title || "Passage"} Questions`;
    },
    examId() {
      return this.$route.query.examId;
    }
  },
  watch: {},

  async created() {
    if (this.$route.query.questionOrder) {
      this.isDisplaySingleQuestion = true;
    }
    await this.fetchPassage();
    const { full_name, subjects } = await satApi.getTest(
      this.$route.query.examId
    );
    this.testName = full_name;
    this.sectionName = "Reading";
  },
  methods: {
    filterQuestion(questions) {
      if (!this.isDisplaySingleQuestion) return questions;
      return questions.filter(
        ({ order }) => order === Number(this.$route.query.questionOrder)
      );
    },
    changeToSingleQuestionMode(isDisplaySingleQuestion) {
      if (isDisplaySingleQuestion) {
        this.enterToSingleQuestion(this.passage.questions[0].order);
      }
    },
    async fetchPassage() {
      this.passage = await satApi.getPassage(this.$route.query.passageId);
    }
  }
};
</script>

<style scoped>
.ivy-content {
  background: #fff;
  margin: 20px 0;
  padding: 10px 20px;
  border: 1px solid #dcdfe6;
}
.ivy-passage {
  height: calc(100vh - 330px);
  overflow: auto;
  padding-right: 20px;
}
.ivy-quesitons {
  height: calc(100vh - 330px);
  padding: 0 20px;
  overflow: auto;
}
.shortcutBtns > a {
  margin-right: 8px;
}
.questionOrder {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 8px 8px 0px;
  border: 1px solid var(--themeColor);
  border-radius: 4px;
  height: 28px;
  width: 28px;
}
.questionOrder:hover {
  color: #fff;
  background: var(--themeColor);
}
</style>
